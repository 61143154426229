import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    '& .infoList': {
        listStyle: 'none',
        marginBottom: toRem(80),
        maxWidth: 1150,

        [theme.breakpoints.up('1150')]: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: toRem(120)
        },

        li: {
            marginBottom: toRem(20)
        },

        a: {
            color: theme.colors.goldLight
        },

        '.info-title': {
            textTransform: 'uppercase',
            color: theme.colors.goldDark,
            fontSize: toRem(11),
            display: 'block'
        },

        '.info-value': {
            margin: 0,
            fontSize: toRem(17)
        }
    }
})

export default style
