import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'
import { Flex, Box } from 'reflexbox'
import getConfig from 'next/config'
import { useBoatList } from 'helpers/hooks'
import dynamic from 'next/dynamic'
import { useMemo } from 'react'
import Loader from 'components/Loader'

import PageTitle from 'components/PageTitle'
import Button from 'components/Button'
import { getImageURL, getBoatLink } from 'helpers/middleware'

import ArrowRightIcon from 'public/icons/arrow-right.svg'
import ContactInfo from 'components/ContactInfo'

import layoutTheme from 'theme/layout-theme'
import { NextSeo } from 'next-seo'

import * as Sentry from '@sentry/node'

const { publicRuntimeConfig } = getConfig()
const { CLOUDINARY_URL, DOMAIN_ROOT } = publicRuntimeConfig

const PhotoGallerySlider = dynamic(() => import('components/PhotoGallerySlider'), {
    ssr: false
})

const Error = ({ statusCode, t, i18n }) => {
    const seoData = {
        title: `${statusCode === 404 ? t('page-not-found') : 'Something went wrong.'} | Merk & Merk`,
        description: '',
        openGraph: {
            title: `${statusCode === 404 ? t('page-not-found') : 'Something went wrong.'} | Merk & Merk`,
            description: '',
            url: DOMAIN_ROOT,
            images: [
                {
                    url: `${CLOUDINARY_URL}/v1582634519/Static/default_aiv2r3.jpg`
                }
            ]
        }
    }
    const { data: boatsListing, isLoading } = useBoatList()

    const featuredYachts = useMemo(() => {
        const language = i18n?.language || 'en'

        const featured =
            boatsListing && boatsListing.length > 0
                ? boatsListing
                      .filter(boat => boat.featured && boat.published)
                      // eslint-disable-next-line
                      .sort((boatA, boatB) => boatB?.created_at?._seconds - boatA?.created_at?._seconds)
                      .map(({ attachments, info, slug, price }) => ({
                          url: getImageURL((attachments || {}).images, {
                              position: 'listing',
                              size: 'featured',
                              hasFallback: true
                          }),
                          name: info?.model || info?.name,
                          condition: t(`controls:${info.condition}`),
                          year: info.year,
                          length: info.length,
                          price: price.displayValue === '0' ? t('price-upon-request') : price.displayValue,
                          vat: price.vat,
                          boatURL: getBoatLink(info.type, slug, language).href,
                          boatURLas: getBoatLink(info.type, slug, language).as,
                          currency: price.displayValue === '0' ? '' : price.currency
                      }))
                : []

        return featured
    }, [boatsListing])

    return (
        <>
            <NextSeo {...seoData} />
            <Flex
                theme={layoutTheme}
                justifyContent="space-between"
                alignItems="flex-end"
                maxWidth={1350}
                mx="auto"
                px={[40, null, null, null, null, 0]}
                mt={[40, null, null, 40]}
                mb={[30, null, null, 80]}>
                <Box theme={layoutTheme} mr={[0, 0, 0, 0, 20]}>
                    <PageTitle subtitle={t('page-not-found')} />
                </Box>

                <Box theme={layoutTheme}>
                    <Button small white variation="primary rounded" link="/" hasIcon>
                        {t('homepage')}
                        <ArrowRightIcon />
                    </Button>
                </Box>
            </Flex>

            {featuredYachts.length > 0 && (
                <Box
                    theme={layoutTheme}
                    bg="#003D5A"
                    pt={[40, null, null, 80, 80]}
                    pb={[30, null, null, 20, 20]}
                    mb={80}>
                    <Box
                        theme={layoutTheme}
                        fontSize={[22, null, null, 28, 28]}
                        fontWeight={500}
                        mb={30}
                        pl={[20, null, null, 40, null, 160]}>
                        {t('page-not-found-listing')}
                    </Box>

                    <Box theme={layoutTheme} mb={[60, null, null, null]}>
                        <PhotoGallerySlider images={featuredYachts} specialOffers />
                    </Box>
                </Box>
            )}

            <Box theme={layoutTheme} mb={[80, null, null, 80]}>
                {isLoading && <Loader height="100px" maxOpacity={0.5} />}
            </Box>

            <Box theme={layoutTheme} px={20} mt={[80, null, null, 80]} mb={80} maxWidth={1360} mx="auto">
                <PageTitle title={t('contact')} subtitle={t('get-in-touch-with-us')} titleTag="h1" />
            </Box>

            <Box theme={layoutTheme} variant="center1360" px={20}>
                <ContactInfo />
            </Box>
        </>
    )
}

Error.getInitialProps = async ({ res, err }) => {
    let statusCode
    if (res) ({ statusCode } = res)
    else if (err) ({ statusCode } = err)
    else statusCode = null

    return {
        namespacesRequired: ['common', 'meta-data'],
        statusCode
    }
}

Error.propTypes = {
    statusCode: PropTypes.number,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.oneOfType([() => null, PropTypes.object]).isRequired
}

Error.defaultProps = {
    statusCode: undefined
}

const SentryError = ({ statusCode, hasGetInitialPropsRun, err, t }) => {
    if (!hasGetInitialPropsRun && err) {
        Sentry.captureException(err)
    }
    return <Error statusCode={statusCode} t={t} />
}

SentryError.getInitialProps = async ({ res, err, asPath }) => {
    const errorInitialProps = await Error.getInitialProps({
        res,
        err
    })

    errorInitialProps.hasGetInitialPropsRun = true

    if (res?.statusCode === 404) {
        // return { statusCode: 404 }
        return errorInitialProps
    }
    if (err) {
        Sentry.captureException(err)
        await Sentry.flush(2000)
        return errorInitialProps
    }

    // If this point is reached, getInitialProps was called without any
    // information about what the error might be. This is unexpected and may
    // indicate a bug introduced in Next.js, so record it in Sentry
    Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`))
    await Sentry.flush(2000)

    return errorInitialProps
}

SentryError.propTypes = {
    statusCode: PropTypes.number,
    hasGetInitialPropsRun: PropTypes.bool,
    err: PropTypes.oneOf([PropTypes.string, PropTypes.shape({})]),
    t: PropTypes.func.isRequired
}

SentryError.defaultProps = {
    statusCode: undefined,
    err: undefined,
    hasGetInitialPropsRun: false
}

export default withTranslation('common', 'meta-data')(SentryError)
