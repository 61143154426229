import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'

import style from './ContactInfo.style'

const ContactInfoStyled = styled.div(props => ({ ...style(props) }))

const ContactInfo = ({ t }) => {
    return (
        <ContactInfoStyled>
            <ul className="infoList">
                <li>
                    <span className="info-title">{t('mobile')}</span>
                    <span className="info-value">
                        <a href="tel:+385992582223">+385 (0)91 346 - 7775</a>
                    </span>
                </li>

                <li>
                    <span className="info-title">E-mail</span>
                    <span className="info-value">
                        <a href="mailto:office@merkandmerk.com">office@merkandmerk.com</a>
                    </span>
                </li>

                <li>
                    <span className="info-title">{t('company')}</span>
                    <span className="info-value">Merk & Merk Jahte Servis Tribunj d.o.o</span>
                </li>

                <li>
                    <span className="info-title">{t('vat-id')}</span>
                    <span className="info-value">02419298605</span>
                </li>
            </ul>
        </ContactInfoStyled>
    )
}

ContactInfo.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('common')(withTheme(ContactInfo))
